import React, { PureComponent } from 'react';
import { Form, Row, Col } from 'antd';
import SEO from '@components/SEO';
import Input from '@components/Input';
import Footer from '@views/default-layout/footer/Footer';
import { message } from '@components/Message';
import IconArrow2 from '@icons/IconArrow2';
import { userService } from '@services/userService';

class index extends PureComponent {
   state = {
      submitting: false,
      requestSent: false,
      email: '',
   };
   _mounted = false;

   componentWillUnmount() {
      this._mounted = false;
   }

   componentDidMount() {
      this._mounted = true;
   }

   onFinish = async (values) => {
      const email = values?.email || '';
      await this.setState({ submitting: true });
      const responseData = await userService.resetPassword(email);
      if (responseData.isSuccess && responseData.data) {
         if (this.state.requestSent) {
            message.success('Resend email successfully.');
         }
         this.setState({
            requestSent: true,
            email: email,
         });
      } else {
         message.error(responseData.message);
      }
      if (this._mounted) {
         this.setState({ submitting: false });
      }
   };

   render() {
      const { email, requestSent, submitting } = this.state;
      return (
         <>
            <SEO title={'Reset Password'} />
            <div className="oauth-sub-page">
               <div className="section-title">
                  <span className="section-title-name animate__animated animate__fadeIn">
                     {!requestSent ? 'Having trouble signing in?' : 'Check Your Email'}
                     <span className="s-arrow animate__animated animate__rotateInDownLeft animate__slow">
                        <IconArrow2 />
                     </span>
                  </span>
                  {!requestSent && (
                     <div className="section-title--desc">
                        Enter your email to reset password.
                     </div>
                  )}
               </div>
               <Row gutter={30}>
                  <Col lg={10} md={12} sm={24} xs={24}>
                     {!requestSent ? (
                        <Form className="submit-form" onFinish={this.onFinish}>
                           <Form.Item
                              name="email"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Please input your Email.',
                                 },
                                 {
                                    type: 'email',
                                    message: 'Please enter a valid email address.',
                                 },
                              ]}
                           >
                              <Input placeholder="Email" />
                           </Form.Item>
                           <Form.Item>
                              <button
                                 className="common-button"
                                 type="primary"
                                 disabled={submitting}
                              >
                                 {submitting ? 'Submitting' : 'Submit'}
                              </button>
                           </Form.Item>
                        </Form>
                     ) : (
                        <div className="request-sent">
                           <p>
                              We've sent an email to:{' '}
                              <span className="email">{email}</span>.<br />
                              Please check the email for instructions to reset your
                              password.
                           </p>
                           <button
                              className="common-button__outline"
                              disabled={submitting}
                              onClick={() => this.onFinish({ email })}
                           >
                              {submitting ? 'Re-sending' : 'Resend email'}
                           </button>
                        </div>
                     )}
                  </Col>
               </Row>
            </div>
            <Footer />
         </>
      );
   }
}

export default index;
